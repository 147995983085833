import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../axiosInstance";

export const getCricketDetails = createAsyncThunk(
    'cricket/getCricketDetails',
    async () => {
        try {
            const response = await axiosInstance.get(`api/cricket/adminMarkedMatches`);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }
);

export const getQuestions = createAsyncThunk(
    'cricket/getQuestions',
    async (matchId) => {
        try {
            const response = await axiosInstance.get(`api/cricket/questions?matchId=${matchId}`);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }
);
export const getUserPendingQuestion = createAsyncThunk(
    'cricket/getUserPendingQuestion',
    async (params) => {
        const { userId, currentMatchId } = params
        try {
            const response = await axiosInstance.post(`api/cricket/getUserPendingQuestion`, { userId, matchId: currentMatchId });
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }
);
export const setNotification = createAsyncThunk(
    'cricket/setNotification',
    async (params) => {
        const { matchId, userId, questionId } = params;
        try {
            const response = await axiosInstance.post(`api/cricket/notifications`, { matchId, questionId, userId });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const getCricketInfo = createAsyncThunk(
    'cricket/getCricketInfo',
    async () => {
        try {
            const response = await axiosInstance.get(`api/cricket/getCricketInfo`);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    }
);

export const getUserBets = createAsyncThunk(
    'cricket/getUserBets',
    async ({ userId, status, startDate, endDate }) => {
        try {
            if (status && status !== "date") {
                const response = await axiosInstance.get(`/api/cricket/getUserBets/${userId}?status=${status}`);
                return response.data.data;
            }
            else if (startDate && endDate) {
                const response = await axiosInstance.get(`/api/cricket/getUserBets/${userId}?startDate=${startDate}&endDate=${endDate}`);
                return response.data.data;
            } else if (!status) {
                const response = await axiosInstance.get(`/api/cricket/getUserBets/${userId}`);
                return response.data.data;
            }
        } catch (error) {
            throw error;
        }
    }
);

const initialState = {
    isLoading: false,
    isError: false,
    cricketDetail: null,
    betQuestions: {
        isLoading: false,
        isError: false,
        matchId: "",
        questions: [],
    },
    userPendingQuestions: [],
    userSelectedAmount: 100,
    allBets: { hasFetch: false, allBets: [] },
    winBets: { hasFetch: false, winBets: [] },
    lossBets: { hasFetch: false, lossBets: [] },
    pendingBets: { hasFetch: false, pendingBets: [] },
    dateBets: [],
    totalBets: {},
    totalMatchbets: {},
    cricketInfo: null,
};

const cricketSlice = createSlice({
    name: "cricket",
    initialState,
    reducers: {
        updateBetQuestions: (state, action) => {
            const question = action.payload;
            if (question.matchId === state.betQuestions.matchId) {
                if (Array.isArray(question.questions)) {
                    state.betQuestions = { ...state.betQuestions, questions: question.questions }
                } else {
                    const questionIndex = state.betQuestions.questions.findIndex(q => q.questionId === question.question.questionId);
                    if (questionIndex !== -1) {
                        state.betQuestions.questions[questionIndex] = question.question;
                    } else {
                        state.betQuestions.questions.push(question.question);
                    }
                }
            }
        },
        setTotalBets: (state, action) => {
            const { matchId, questionId, totalBets } = action.payload;
            if (!state.totalBets[matchId]) {
                state.totalBets[matchId] = {};
            }
            state.totalBets[matchId][questionId] = totalBets;
            localStorage.setItem("totalQuestionsBets", JSON.stringify(state.totalBets));
        },
        setMatchTotalBets: (state, action) => {
            const { matchId, totalBets } = action.payload;
            if (!state.totalMatchbets[matchId]) {
                state.totalMatchbets[matchId] = {};
            }
            state.totalMatchbets[matchId] = totalBets;
            localStorage.setItem("totalMatchBets", JSON.stringify(state.totalMatchbets));
        },
        updateUserPendingQuestions: (state, action) => {
            const question = action.payload;
            const questionIndex = state.userPendingQuestions.findIndex(q => question.matchId === q.matchId && q.questionId === question.question.questionId);
            if (questionIndex !== -1) {
                state.userPendingQuestions[questionIndex] = question;
            } else {
                state.userPendingQuestions.push(question);
            }
        },
        setUserBetStatus: (state, action) => {
            const result = action.payload;
            const betIndex = state.allBets.allBets.findIndex(bet => bet.betId === result.betId);

            if (betIndex !== -1) {
                state.allBets.allBets[betIndex] = result;
            } else {
                state.allBets.allBets = [result, ...state.allBets.allBets];
            }

            if (result.result === "win" || result.result === "loss") {
                state.pendingBets.pendingBets = state.pendingBets.pendingBets.filter(bet => bet.betId !== result.betId);
            }

            if (result.result === "win") {
                state.winBets.winBets = [result, ...state.winBets.winBets];
            } else if (result.result === "loss") {
                state.lossBets.lossBets = [result, ...state.lossBets.lossBets];
            } else {
                state.pendingBets.pendingBets = [result, ...state.pendingBets.pendingBets];
            }
        },
        setUserSelectedAmount: (state, action) => {
            state.userSelectedAmount = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder
            // getCricketDetails actions
            .addCase(getCricketDetails.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(getCricketDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.cricketDetail = action.payload;
            })
            .addCase(getCricketDetails.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // getQuestions actions
            .addCase(getQuestions.pending, (state) => {
                state.betQuestions.isLoading = true;
                state.betQuestions.isError = false;
            })
            .addCase(getQuestions.fulfilled, (state, action) => {
                state.betQuestions.isLoading = false;
                state.betQuestions = action.payload;
            })
            .addCase(getQuestions.rejected, (state) => {
                state.betQuestions.isLoading = false;
                state.betQuestions.isError = true;
            })
            // getUserPendingQuestion actions
            .addCase(getUserPendingQuestion.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(getUserPendingQuestion.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userPendingQuestions = action.payload;
            })
            .addCase(getUserPendingQuestion.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // getUserBets actions
            .addCase(getUserBets.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(getUserBets.fulfilled, (state, action) => {
                state.isLoading = false;
                const { status } = action.meta.arg;

                if (!status) {
                    state.allBets = {
                        hasFetch: true,
                        allBets: action.payload
                    };
                } else if (status === 'win') {
                    state.winBets = {
                        hasFetch: true,
                        winBets: action.payload
                    };
                } else if (status === 'loss') {
                    state.lossBets = {
                        hasFetch: true,
                        lossBets: action.payload
                    };
                } else if (status === 'pending') {
                    state.pendingBets = {
                        hasFetch: true,
                        pendingBets: action.payload
                    };
                } else {
                    state.dateBets = action.payload;
                }
            })
            .addCase(getUserBets.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // getCricketInfo actions
            .addCase(getCricketInfo.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(getCricketInfo.fulfilled, (state, action) => {
                state.cricketInfo = action.payload;
            })
            .addCase(getCricketInfo.rejected, (state) => {
                state.isError = true;
            });
    },
});

export const { updateBetQuestions, updateUserPendingQuestions, setTotalBets, setMatchTotalBets, setUserBetStatus, setUserSelectedAmount } = cricketSlice.actions;
export default cricketSlice.reducer;
