import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const BetConfirmedModel = ({ show, setBetConfirmedModel }) => {
  const { userSelectedAmount } = useSelector((state) => state.cricket);
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setBetConfirmedModel(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [show, setBetConfirmedModel]);

  return (
    <Modal
      show={show}
      onHide={() => setBetConfirmedModel(false)}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header className="py-2 d-flex justify-content-between modelHeader text-white">
        <h3 className="modal-title flex-fill text-center fw-bolder">
          Bet Confirmed
        </h3>
        <i
          onClick={() => {
            setBetConfirmedModel(false);
          }}
          className="bi bi-x-circle fs-2"
          type="button"
          aria-label="Close"
        ></i>
      </Modal.Header>

      <Modal.Body className="p-0 pt-3 text-center mb-3">
        <img src="/assets/betSuccess.svg" alt="vector" className="my-3" />
        <h1 className="text-success fw-bolder my-4">Your Bet Placed</h1>
        <p className="m-0">Betting Amount</p>
        <h3 className="fw-bolder lightGrayBg rounded lightGrayBg mx-5 py-2 my-3">
          ₹{userSelectedAmount.toLocaleString("en-IN")}
        </h3>
      </Modal.Body>
    </Modal>
  );
};

export default BetConfirmedModel;
