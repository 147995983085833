import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { setNotification } from "../redux/features/cricketSlice";

const UpcomingMatch = ({ match, calculateTimeLeft }) => {
  const { league, round, team_a, team_b, starting_at, matchId } = match;
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState("");
  const [matchNotification, setMatchNotification] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));

  useEffect(() => {
    const updateTimeLeft = () => {
      const updatedTime = calculateTimeLeft(starting_at);
      setTimeLeft(updatedTime);
    };

    updateTimeLeft();

    const timer = setInterval(updateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [starting_at, calculateTimeLeft]);

  // useEffect(() => {
  //   if (matchNotification) {
  //     dispatch(setNotification({ matchId, userId }));
  //   }
  // }, [matchNotification]);

  return (
    <div className="p-0 border rounded mb-4 bg-backgroundImg shadow-sm">
      <div className="redShadowBg rounded-top text-white p-0 d-flex align-items-center justify-content-between py-1">
        <div
          className="m-0 fw-bold d-flex align-items-center"
          style={{ fontSize: "14px" }}
        >
          <img
            src="/assets/whiteBall.svg"
            alt="ball"
            className="px-1 custom-img-25"
          />
          {league}
          <span
            className="ps-1 fs-10"
            style={{ lineHeight: "20px" }}
          >{`(${round})`}</span>
        </div>
        {/* <img
          src={
            matchNotification
              ? "/assets/fillBellIcon.svg"
              : "/assets/bellIcon.svg"
          }
          alt="notificationBell"
          className="pe-2 custom-img-25"
          onClick={() => setMatchNotification((prev) => !prev)}
        /> */}
      </div>
      <div className="p-0 py-2 d-flex justify-content-between align-items-center letter-spacing-negative">
        <div className="w-50 text-center">
          <div className="d-flex justify-content-center">
            <div
              className="custom-img-30 fw-bold rounded-circle d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#e1dede" }}
            >
              {team_a.code}
            </div>
          </div>
          <span>{team_a.name}</span>
        </div>
        <div className="w-25 text-center">
          <i className="m-0 fw-bold" style={{ color: "#A7A7A7" }}>
            V/S
          </i>
          <p className="m-0 text-danger lightRed fw-bold rounded">{timeLeft}</p>
          <p className="m-0 fs-10">
            {moment.unix(starting_at).format("hh:mm A")}
          </p>
        </div>
        <div className="w-50 text-center">
          <div className="d-flex justify-content-center">
            <div
              className="custom-img-30 fw-bold rounded-circle d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#e1dede" }}
            >
              {team_b.code}
            </div>
          </div>
          <span className="flex-fill">{team_b.name}</span>
        </div>
      </div>
    </div>
  );
};

export default UpcomingMatch;
