import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BetSuccessModel from "./ConfirmModel";
import { setUserSelectedAmount } from "../redux/features/cricketSlice";
import { useWebSocket } from "../../webSocketContext";

const MatchQuestion = ({
  matchQuestion,
  betOverByOver,
  teamA,
  teamB,
  match_id,
  league,
  inning,
  date,
  time,
  currentlyBattingTeamId,
}) => {
  const { userPendingQuestions } = useSelector((state) => state.cricket);
  const {
    questionId,
    question,
    best,
    isLocked,
    result,
    worst,
    worstTeamB,
    bestTeamB,
    runs,
    over,
  } = matchQuestion || {};
  const dispatch = useDispatch();
  const [selectBetModel, setSelectBetModel] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(100);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [questionNotification, setQuestionNotification] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const selectTotalBets = (state, matchId, questionId) => {
    return state.cricket.totalBets[matchId]?.[questionId] || 0;
  };
  const totalBets = useSelector((state) =>
    selectTotalBets(state, match_id, questionId)
  );
  const { webSocket } = useWebSocket();

  const placedBet = async () => {
    setSelectBetModel(false);
    dispatch(setUserSelectedAmount(selectedAmount));

    const subscribeMessage = {
      gameType: "cricket",
      type: "CRICKET_PLACE_BET",
      data: {
        userId: userId,
        question: matchQuestion,
        matchId: match_id,
        betAmount: selectedAmount,
        choice: selectedChoice,
        league: league,
        teams: [teamA, teamB],
        inning: inning || 1,
        matchDate: date,
        matchTime: time,
      },
    };
    webSocket.send(JSON.stringify(subscribeMessage));
  };
  function getOrdinal(n) {
    if (!n) return "Y";
    const lastDigit = n % 10;
    const lastTwoDigits = n % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return n + "th";
    }
    switch (lastDigit) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
      default:
        return n + "th";
    }
  }

  const lockedChoice =
    (userPendingQuestions.length > 0 &&
      userPendingQuestions.find((que) => {
        const isMatchingQuestionId = [4, 5, 6, 7].includes(questionId);
        return (
          match_id === que.matchId &&
          questionId === que.question?.questionId &&
          (isMatchingQuestionId ? over === que.question.over : true)
        );
      })) ||
    "";
  // useEffect(() => {
  //   if (questionNotification) {
  //     dispatch(setNotification({ matchId: match_id, userId, questionId }));
  //   }
  // }, [questionNotification]);

  return (
    <div className={`p-0 border rounded mb-3 bg-backgroundImg shadow-sm `}>
      <div
        className={`${
          betOverByOver ? "bg-primary" : "redShadowBg"
        } rounded-top-3 text-white p-0 d-flex align-items-center justify-content-between`}
      >
        <div className="m-0 fw-bold d-flex align-items-center py-1">
          <img
            src="/assets/whiteBall.svg"
            alt="ball"
            className="px-1 text-white w-img-25"
          />
          {question
            .replace("{{runs}}", runs || "X")
            .replace("{{overNumber}}", getOrdinal(over))}
        </div>
        {/* <img
          src={
            questionNotification
              ? "/assets/fillBellIcon.svg"
              : "/assets/bellIcon.svg"
          }
          alt="notificationBell"
          className="pe-2 custom-img-25"
          onClick={() => setQuestionNotification((prev) => !prev)}
        /> */}
      </div>
      <div className="p-0 py-1 d-flex align-items-center">
        <div className="w-50 flex-fill d-flex align-items-center justify-content-center px-smm-1 py-2 border ms-1">
          {questionId < 4 ? (
            <>
              <div className="d-flex flex-fill justify-content-start align-items-center">
                <div className="d-flex justify-content-center">
                  <div
                    className="custom-img-30  fw-bold rounded-circle d-flex justify-content-center align-items-center"
                    style={{ backgroundColor: "#e1dede", position: "relative" }}
                  >
                    {teamA?.code}
                    {currentlyBattingTeamId && (
                      <img
                        src={
                          currentlyBattingTeamId === teamA?.teamId
                            ? "/assets/batIcon.svg"
                            : "/assets/ballIcon.svg"
                        }
                        alt="icon"
                        className="absolute-image"
                      />
                    )}
                  </div>
                </div>
                <div className="ps-1 text-center">{teamA?.name}</div>
              </div>
              <div className="w-50 text-end">
                <button
                  onClick={() => {
                    setSelectBetModel(true);
                    setSelectedChoice("teamA");
                  }}
                  className={`border rounded-pill py-1 pe-2 fs-12 ${
                    lockedChoice.choice === "teamA"
                      ? "bg-warning"
                      : lockedChoice || isLocked
                      ? "bg-secondary"
                      : "greenShadowBg"
                  } text-white fw-bold`}
                  disabled={lockedChoice || isLocked}
                >
                  Bet <i className="bi bi-chevron-right" />
                </button>
              </div>
            </>
          ) : (
            <button
              onClick={() => {
                setSelectBetModel(true);
                setSelectedChoice("yes");
              }}
              className={`border rounded-pill py-1 px-5 ${
                lockedChoice.choice === "yes"
                  ? "bg-warning"
                  : lockedChoice || isLocked
                  ? "bg-secondary"
                  : "greenShadowBg"
              } text-white m-0 fw-bold`}
              disabled={lockedChoice || isLocked}
            >
              Yes
            </button>
          )}
        </div>
        <div className="w-50 d-flex flex-fill align-items-center justify-content-center px-smm-1 py-2 border mx-1">
          {questionId < 4 ? (
            <>
              <div className="d-flex flex-fill justify-content-start align-items-center">
                <div className="d-flex justify-content-center">
                  <div
                    className="custom-img-30  fw-bold rounded-circle d-flex justify-content-center align-items-center"
                    style={{ backgroundColor: "#e1dede", position: "relative" }}
                  >
                    {teamB?.code}
                    {currentlyBattingTeamId && (
                      <img
                        src={
                          currentlyBattingTeamId === teamB?.teamId
                            ? "/assets/batIcon.svg"
                            : "/assets/ballIcon.svg"
                        }
                        alt="icon"
                        className="absolute-image"
                      />
                    )}
                  </div>
                </div>
                <div className="ps-1 text-center">{teamB?.name}</div>
              </div>
              <div className="w-50 text-end">
                <button
                  onClick={() => {
                    setSelectBetModel(true);
                    setSelectedChoice("teamB");
                  }}
                  className={`border rounded-pill py-1 pe-2 fs-12 ${
                    lockedChoice.choice === "teamB"
                      ? "bg-warning"
                      : lockedChoice || isLocked
                      ? "bg-secondary"
                      : "greenShadowBg"
                  } text-white fw-bold`}
                  disabled={lockedChoice || isLocked}
                >
                  Bet <i className="bi bi-chevron-right"></i>
                </button>
              </div>
            </>
          ) : (
            <button
              onClick={() => {
                setSelectBetModel(true);
                setSelectedChoice("no");
              }}
              className={`border rounded-pill py-1 px-5 ${
                lockedChoice.choice === "no"
                  ? "bg-warning"
                  : lockedChoice || isLocked
                  ? "bg-secondary"
                  : "greenShadowBg"
              } text-white m-0 fw-bold`}
              disabled={lockedChoice || isLocked}
            >
              No
            </button>
          )}
        </div>
      </div>

      <div className="fs-10 fw-bold mx-2">
        {questionId === 3 ? (
          <>
            <p className="m-0">
              <span>{teamA?.code} : </span>
              <span className="text-success ps-1">
                {" "}
                {`${worst || 101}%-${best || 170}%`}
              </span>
            </p>
            <p className="m-0">
              <span>{teamB?.code} : </span>
              <span> {}</span>
              <span className="text-success ps-1">
                {" "}
                {`${worstTeamB || 170}%-${bestTeamB || 200}%`}
              </span>
            </p>
          </>
        ) : (
          <>
            <span>Up-to : </span>
            <span className="text-success ps-1">
              {`${worst || 101}%-${best || 170}%`}{" "}
            </span>
          </>
        )}
      </div>

      <div
        className={`p-0 d-flex align-items-center justify-content-between ${
          betOverByOver ? "skyBlueBg" : "lightYellowBg"
        } `}
      >
        <div className="py-1 fs-12">
          <i className="bi bi-trophy px-1 text-danger"></i>
          {totalBets} People Joined
        </div>
        <div className="w-50 d-flex justify-content-end">
          {questionId >= 4 && questionId <= 6 ? (
            ""
          ) : (
            <div className="w-65 d-flex border rounded bg-white ps-1 me-2">
              <div className="pe-1">
                <img src="/assets/result.svg" alt="result" />
              </div>
              <span className="w-50 fs-12 fw-bold">Result:</span>
              <span className="flex-fill text-center fs-12 fw-bold ">
                {result === "yes" ? "YES" : result === "no" ? "NO" : result}
              </span>
            </div>
          )}
        </div>
      </div>
      {selectBetModel && (
        <BetSuccessModel
          show={true}
          setSelectBetModel={setSelectBetModel}
          selectedAmount={selectedAmount}
          placedBet={placedBet}
          setSelectedAmount={setSelectedAmount}
        />
      )}
    </div>
  );
};

export default MatchQuestion;
