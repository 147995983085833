import axiosInstance from "../axiosInstance";

export const fetchUserBalance = async (userId) => {
    try {
        const response = await axiosInstance.get(`api/user/fetchUserBalance/${userId}`);
        const balance = response.data.userBalance;
        localStorage.setItem('userBalance', JSON.stringify(balance));
        return balance;
    } catch (error) {
        throw error;
    }
}