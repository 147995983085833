import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { setLiveScores } from "../redux/features/webSocketSlice";
import { setMatchTotalBets } from "../redux/features/cricketSlice";

const LiveMatch = ({ match, calculateTimeLeft }) => {
  const {
    type,
    round,
    matchId,
    play_status,
    starting_at,
    team_a,
    team_b,
    tossResult,
    isOpenForBet,
  } = match;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState("");
  const [openForBetByState, setOpenForBetByState] = useState(isOpenForBet);
  const { closeOrOpenBet, liveScores } = useSelector(
    (state) => state.webSocket
  );
  const liveScore = liveScores[matchId];
  const selectTotalBets = (state, matchId) => {
    return state.cricket.totalMatchbets[matchId] || 0;
  };
  const totalMatchBets = useSelector((state) =>
    selectTotalBets(state, matchId)
  );
  useEffect(() => {
    const storedScores = JSON.parse(localStorage.getItem("liveScores"));
    const totalMatchBets =JSON.parse(localStorage.getItem("totalMatchBets"));
    if (storedScores) {
      dispatch(setLiveScores(storedScores));
    }
      if (totalMatchBets) {
        Object.entries(totalMatchBets).forEach(([matchId, totalBets]) => {
            dispatch(setMatchTotalBets({ matchId, totalBets }));
        });
      }
  }, []);

  useEffect(() => {
    const updateTimeLeft = () => {
      const updatedTime = calculateTimeLeft(starting_at);
      setTimeLeft(updatedTime);
    };

    updateTimeLeft();

    const timer = setInterval(updateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [starting_at, calculateTimeLeft]);

  useEffect(() => {
    if (closeOrOpenBet.matchId === matchId) {
      setOpenForBetByState(false);
    }
  }, [closeOrOpenBet, matchId]);

  const formattedTime = moment.unix(starting_at).format("hh:mm A");
  const formattedDate = moment.unix(starting_at).format("yyyy-MM-DD");

  const status = {
    scheduled: "NS",
    pre_match: "NS",
    in_play: "Live",
    innings_break: "Inning Break",
    rain_delay: "Rain Delay",
    abandoned: "Cancel",
    result: "Result",
    canceled: "Canceled",
    start_delay: "Start Delay",
    strategic_timeout: "Time Out",
  };

  return (
    <div className="my-3 border rounded bg-backgroundImg shadow-sm">
      <div className="row m-0">
        <div className="col-6 rounded-top rounded-end-0 bg-warning p-0 d-flex align-items-center justify-content-center">
          <div
            className="m-0 d-flex flex-fill align-items-start text-capitalize fw-bold"
            style={{ fontSize: "14px" }}
          >
            <img src="/assets/blackBall.svg" alt="ball" className="px-1" />
            {type}{" "}
            <span
              className="fs-10 ps-1"
              style={{ lineHeight: "22px" }}
            >{`(${round})`}</span>
          </div>
        </div>
        <div className="col-4 p-0 redShadowBg d-flex align-items-center">
          <div className="d-flex justify-content-around align-items-stretch text-center text-white flex-fill">
            <div className="ps-1 d-flex flex-column align-items-center justify-content-center lh-sm py-1">
              <p className="m-0 fs-10" style={{ fontWeight: 300 }}>
                Runs
              </p>
              <div className="m-0">
                {(liveScore &&
                  liveScore.currentBattingTeam &&
                  Object.keys(liveScore).length > 0 &&
                  liveScore.runs.length > 0 &&
                  liveScore.runs.find(
                    (match) =>
                      match.team.teamId === liveScore.currentBattingTeam.teamId
                  ).runs) ||
                  "0"}
              </div>
            </div>
            <span className="my-2 border border-danger" />
            <div className="d-flex flex-column align-items-center justify-content-center lh-sm py-1">
              <p className="m-0 fs-10" style={{ fontWeight: 300 }}>
                Wickets
              </p>
              <div className="m-0">
                {(liveScore &&
                  liveScore.currentBattingTeam &&
                  Object.keys(liveScore).length > 0 &&
                  liveScore.runs.length > 0 &&
                  liveScore.runs.find(
                    (match) =>
                      match.team.teamId === liveScore.currentBattingTeam.teamId
                  ).wickets) ||
                  "0"}
              </div>
            </div>
            <span className="my-2 border border-danger" />
            <div className="pe-1 d-flex flex-column align-items-center justify-content-center lh-sm py-1">
              <p className="m-0 fs-10" style={{ fontWeight: 300 }}>
                Overs
              </p>
              <div className="m-0">
                {(liveScore &&
                  liveScore.currentBattingTeam &&
                  Object.keys(liveScore).length > 0 &&
                  liveScore.runs.length > 0 &&
                  liveScore.runs.find(
                    (match) =>
                      match.team.teamId === liveScore.currentBattingTeam.teamId
                  ).overs) ||
                  "0"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 p-0 bg-danger d-flex align-items-center justify-content-center rounded-top rounded-start-0">
          <div className="text-white text-center">
            {play_status === "in_play" && (
              <i className="bi bi-record-fill pe-1"></i>
            )}
            <span className="fw-bold">
              {status[play_status] || play_status}
            </span>
          </div>
        </div>
      </div>
      <div className="row py-1 mx-1 letter-spacing-negative">
        <div className="col-9 p-0 d-flex justify-content-between align-items-center">
          <div className="w-50">
            <div className="d-flex justify-content-center">
              <div
                className="custom-img-30 fw-bold rounded-circle d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#e1dede", position: "relative" }}
              >
                {team_a.code}
                {liveScore && liveScore.currentBattingTeam && (
                  <img
                    src={
                      liveScore.currentBattingTeam.teamId === team_a.teamId
                        ? "/assets/batIcon.svg"
                        : "/assets/ballIcon.svg"
                    }
                    alt="icon"
                    className="absolute-image"
                  />
                )}
              </div>
            </div>
            <div className="text-center">{team_a.name}</div>
          </div>
          <div className="text-center">
            <i className="m-0 fw-bold" style={{ color: "#A7A7A7" }}>
              V/S
            </i>
            <p className="m-0 text-danger lightRed fw-bold rounded px-1 text-truncate">
              {timeLeft}
            </p>
            <p className="m-0 fs-10"> {formattedTime}</p>
          </div>

          <div className="w-50">
            <div className="d-flex justify-content-center">
              <div
                className="custom-img-30 fw-bold rounded-circle d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#e1dede", position: "relative" }}
              >
                {team_b.code}
                {liveScore && liveScore.currentBattingTeam && (
                  <img
                    src={
                      liveScore.currentBattingTeam.teamId === team_b.teamId
                        ? "/assets/batIcon.svg"
                        : "/assets/ballIcon.svg"
                    }
                    alt="icon"
                    className="absolute-image"
                  />
                )}
              </div>
            </div>
            <div className="text-center">{team_b.name}</div>
          </div>
        </div>
        <div className="col-3 p-0 mt-3 d-flex  justify-content-end">
          <div>
            <button
              onClick={() =>
                navigate("/join-match", {
                  state: { match, formattedTime, formattedDate },
                })
              }
              className={`border rounded-pill p-0 ps-2 pe-1 py-1 greenShadowBg text-white fw-bold 
                 
                  ${
                    openForBetByState
                      ? "greenShadowBg"
                      : closeOrOpenBet.matchId === matchId &&
                        closeOrOpenBet.status
                      ? "greenShadowBg"
                      : "disabledDiv"
                  }
                `}
              disabled={
                openForBetByState
                  ? false
                  : closeOrOpenBet.matchId === matchId && closeOrOpenBet.status
                  ? false
                  : true
              }
            >
              Bet Now <i className="bi bi-chevron-right" />
            </button>
          </div>
        </div>
      </div>
      <div className="text-center text-danger">
        {liveScore &&
          (liveScore.currentBattingTeam
            ? Object.keys(liveScore).length > 0 &&
              (liveScore.requiredScore
                ? `${liveScore.currentBattingTeam.name} need ${liveScore.requiredScore}`
                : tossResult)
            : tossResult)}
      </div>

      <div className="w-75 lightYellowBg">
        <img
          src="/assets/trophy.svg"
          alt="ball"
          className="ps-2 custom-img-20"
        />
        <span className="px-2 text-danger fw-bold">{totalMatchBets}</span>
        <span className="text-secondary fw-normal">Contestant Joined</span>
      </div>
    </div>
  );
};

export default LiveMatch;
