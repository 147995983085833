import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

const BetErrorModel = ({ show, setBetErrorModel, errormsg }) => {

    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                setBetErrorModel(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [show, setBetErrorModel]);


    return (
        <Modal
            show={show}
            onHide={() => setBetErrorModel(false)}
            centered
            size="md"
            backdrop="static"
        >
            <Modal.Header className="py-2 d-flex justify-content-between modelHeader text-white">
                <h3 className="modal-title flex-fill text-center fw-bolder">
                Bet Decline
                </h3>
                <i
                    onClick={() => {
                        setBetErrorModel(false);
                    }}
                    className="bi bi-x-circle fs-2"
                    type="button"
                    aria-label="Close"
                ></i>
            </Modal.Header>

            <Modal.Body className="p-0 pt-3 text-center mb-3">
            <img src='/assets/error.svg' alt="decline" className="my-3 w-50 h-50" />
            <h1 className="text-danger fw-bolder my-4">{errormsg}</h1>
            </Modal.Body>
        </Modal>
    );
};

export default BetErrorModel;
