import React from 'react';

const ReturnPage = () => {
  const return_url = JSON.parse(localStorage.getItem("return_url"));

  return (
    <main className="d-flex justify-content-center align-items-center h-80 shadow bg-light">
      <div className="text-center">
        <h3 className="text-danger fw-bold mb-3">
          Oops! No page found
        </h3>
        <p className="text-muted">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <button
          className="group footerButton rounded-pill my-2 px-4 text-white fw-bold"
          onClick={() => {
            if (return_url) {
              window.location.href = return_url;
            } else {
              alert("No return URL found!");
            }
          }}
        >
          Home
        </button>
      </div>
    </main>
  );
};

export default ReturnPage;
