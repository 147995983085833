import React, { useState, useEffect } from "react";

const LiveScore = ({ liveScore }) => {
  const [filterBowling, setFilterBowling] = useState([]);

  const getRunByBall = (ballState) => {
    const { isWicket, four, six, bye, legBye, noBall, runs, wide } = ballState;
    if (isWicket) return `W`;
    if (wide) return `${runs}wd`;
    if (noBall) return ` ${runs}nb`;
    if (bye) return `${runs}b`;
    if (legBye) return `${runs}lb`;
    if (four) return runs;
    if (six) return runs;
    return runs;
  };
  useEffect(() => {
    if (liveScore?.bowling?.length) {
      const currentOverState = [...liveScore.bowling].reverse();
      const extraBalls = currentOverState.filter(
        (ball) => ball.noBall || ball.wide
      ).length;
      const totalBalls = Math.max(6 + extraBalls, currentOverState.length);
      const paddedOverState = [
        ...currentOverState,
        ...Array(totalBalls - currentOverState.length).fill(""),
      ];
      const currentOverScore = paddedOverState.map((ball) =>
        ball ? getRunByBall(ball) : undefined
      );
      setFilterBowling(currentOverScore);
    } else {
      setFilterBowling(Array(6).fill(undefined));
    }
  }, [liveScore]);

  const status = {
    scheduled: "NS",
    pre_match: "NS",
    in_play: "Live",
    innings_break: "Inning Break",
    rain_delay: "Delayed due to rain",
    abandoned: "Cancel",
    strategic_timeout: "Timeout",
    result: "Result",
  };

  return (
    <header className="header position-sticky">
      {liveScore && Object.keys(liveScore).length > 0 ? (
        <div className="lightGrayBg text-black shadow px-2">
          <div className="d-flex justify-content-between align-items-center pt-1">
            <div className="mb-1 d-flex justify-content-center align-items-center">
              <div
                className="custom-img-30 fw-bold border-0 rounded-circle d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#e1dede" }}
              >
                {liveScore.team_a.code}
              </div>
              {liveScore.currentBattingTeam && (
                <img
                  src={
                    liveScore.currentBattingTeam.teamId ===
                    liveScore.team_a.teamId
                      ? "/assets/batLiveIcon.svg"
                      : "/assets/ballLiveIcon.svg"
                  }
                  alt="tossResult"
                  className="bg-danger ms-1 p-20 rounded-circle"
                />
              )}
            </div>

            <div className="text-center">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ maxWidth: "100%" }}
              >
                <span className="fw-bold ">{liveScore.team_a.name}</span>
                <i className="fw-bold text-secondary  mx-2">V/S</i>
                <span className="fw-bold ">{liveScore.team_b.name}</span>
              </div>
            </div>

            <div className="text-end mb-1 d-flex justify-content-center align-items-center">
              {liveScore.currentBattingTeam && (
                <img
                  src={
                    liveScore.currentBattingTeam.teamId ===
                    liveScore.team_b.teamId
                      ? "/assets/batLiveIcon.svg"
                      : "/assets/ballLiveIcon.svg"
                  }
                  alt="tossResult"
                  className="bg-danger me-1 p-20 rounded-circle"
                />
              )}
              <div
                className="custom-img-30 fw-bold border-0 rounded-circle d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#e1dede" }}
              >
                {liveScore.team_b.code}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mx-1 py-1">
            {(() => {
              const runDetails = liveScore.runs?.find(
                (run) => run.team?.teamId === liveScore.team_a.teamId
              );
              return (
                <div className="w-50 text-start fs-12">
                  <p className="row m-0">
                    <span
                      className="col-4 p-0 fw-bold"
                      style={{ color: "#C10301" }}
                    >
                      Runs
                    </span>
                    <span
                      className="col-2 p-0 text-center"
                      style={{ color: "#C10301" }}
                    >
                      :
                    </span>
                    <span className="col-6 p-0" style={{ color: "#343434" }}>
                      {runDetails
                        ? `${runDetails.runs}${
                            runDetails.wickets === 10
                              ? ""
                              : `/${runDetails.wickets}`
                          }`
                        : "0/0"}
                    </span>
                  </p>
                  <p className="row m-0">
                    <span
                      className="col-4 p-0 fw-bold"
                      style={{ color: "#C10301" }}
                    >
                      Overs
                    </span>
                    <span
                      className="col-2 p-0 text-center"
                      style={{ color: "#C10301" }}
                    >
                      :
                    </span>
                    <span className="col-6 p-0" style={{ color: "#343434" }}>
                      {runDetails ? runDetails?.overs : "0"}
                    </span>
                  </p>
                </div>
              );
            })()}
            <div className="w-50 text-center">
              <div className="d-flex justify-content-center">
                <div
                  className="w-75 border  rounded-pill px-1 pt-1"
                  style={{ background: "#E80000" }}
                />
              </div>
              <div className="fs-12 fw-bold" style={{ color: "#343434" }}>
                {status[liveScore.play_status] || ""}
              </div>
              <div className="text-truncate" style={{ color: "#737373" }}>
                {" "}
                {liveScore &&
                  liveScore.currentBattingTeam &&
                  Object.keys(liveScore).length > 0 &&
                  (liveScore.requiredScore
                    ? `${liveScore.currentBattingTeam.name} need ${liveScore.requiredScore}`
                    : "")}
              </div>
            </div>
            {(() => {
              const runDetails = liveScore.runs?.find(
                (run) => run.team.teamId === liveScore.team_b.teamId
              );
              return (
                <div className="w-50 text-end fs-12">
                  <p className="row m-0 justify-content-end">
                    <span
                      className="col-6 p-0 text-end"
                      style={{ color: "#343434" }}
                    >
                      {runDetails
                        ? `${runDetails.runs}${
                            runDetails.wickets === 10
                              ? ""
                              : `/${runDetails.wickets}`
                          }`
                        : "0/0"}
                    </span>
                    <span
                      className="col-2 p-0 text-center"
                      style={{ color: "#C10301" }}
                    >
                      :
                    </span>
                    <span
                      className="col-4 p-0 fw-bold"
                      style={{ color: "#C10301" }}
                    >
                      Runs
                    </span>
                  </p>
                  <p className="row m-0 justify-content-end">
                    <span
                      className="col-6 p-0 text-end"
                      style={{ color: "#343434" }}
                    >
                      {runDetails ? runDetails?.overs : "0"}
                    </span>
                    <span
                      className="col-2 p-0 text-center"
                      style={{ color: "#C10301" }}
                    >
                      :
                    </span>
                    <span
                      className="col-4 p-0 fw-bold"
                      style={{ color: "#C10301" }}
                    >
                      Overs
                    </span>
                  </p>
                </div>
              );
            })()}
          </div>
          {liveScore.batting.length > 0 && liveScore.bowling.length > 0 && (
            <>
              <div className="border-top border-bottom">
                <table className="table m-0 mt-1 fs-12 w-100">
                  <thead>
                    <tr className=" border fw-bold">
                      <th className="fw-bold text-white p-0 ps-3 redShadowBg2 rounded-start">
                        BATSMAN
                      </th>
                      {["B", "R", "1s", "2s", "3s", "4s", "6s", "SR"].map(
                        (val, index, arr) => (
                          <th
                            key={index}
                            className={`fw-bold text-white text-center p-0 px-2 redShadowBg2 ${
                              index === arr.length - 1 && "rounded-end"
                            }`}
                          >
                            {val}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {liveScore.batting.map((Player, i) => {
                      const {
                        name,
                        player,
                        balls,
                        runs,
                        ones,
                        twos,
                        threes,
                        fours,
                        sixes,
                        strike_rate,
                      } = Player;
                      return (
                        <tr key={i}>
                          <td className="fw-bold p-0 text-secondary text-truncate-player">
                            {name}
                            {player === "Striker" && (
                              <i className="bi bi-record2 ps-1 text-danger"></i>
                            )}
                          </td>
                          {[
                            balls,
                            runs,
                            ones,
                            twos,
                            threes,
                            fours,
                            sixes,
                            Math.floor(strike_rate),
                          ].map((val, index) => (
                            <td
                              key={index}
                              className="text-center p-0 text-secondary"
                            >
                              {val}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-between align-items-center text-white text-center py-1">
                <div className="fs-12">
                  <span
                    className="px-1 py-1 text-black rounded-start bg-warning text-truncate"
                    style={{ color: "#484848" }}
                  >
                    Current Over
                  </span>
                  <span className="py-1 px-1 greenShadowBg">
                    {liveScore.bowling[0]?.currentBall}
                  </span>
                </div>
                {filterBowling.map((ball, index) => (
                  <span
                    key={index}
                    className={`py-2 px-2 border border-light rounded-circle d-flex justify-content-center align-items-center 
                              ${
                                ball === undefined
                                  ? ""
                                  : ball === "W"
                                  ? "bg-danger"
                                  : "greenShadowBg"
                              } scoreCircle`}
                    style={
                      ball === undefined ? { backgroundColor: "#c6c7c8" } : {}
                    }
                  >
                    {ball}
                  </span>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className=" h-20 d-flex justify-content-center align-items-center lightGrayBg shadow">
          <h6 className="text-danger fw-bold m-0 text-center">
            Don't have the live score yet
          </h6>
        </div>
      )}
    </header>
  );
};

export default LiveScore;
