import './App.css';
import { RouterProvider, createBrowserRouter, Outlet, ScrollRestoration } from 'react-router-dom';

import LoadingScreen from './pages/LoadingScreen';
import ReturnPage from './pages/ReturnPage';

import ProtectedRoutes from './ProtectedRoutes';

const AppLayout = () => (
  <>
    <ScrollRestoration />
    <Outlet />
  </>
);

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <LoadingScreen />,
      },
      {
        path: '/*',
        element: <ProtectedRoutes />,
      },
      {
        path: '*',
        element: <ReturnPage />,
      },
    ],
  },
]);

const App = () => (
  <RouterProvider router={router} />
);

export default App;