import { configureStore } from '@reduxjs/toolkit'
import cricketSlice from './features/cricketSlice'
import webSocketSlice from './features/webSocketSlice'

export const store = configureStore({
    reducer: {
        cricket: cricketSlice,
        webSocket: webSocketSlice
    },
});
