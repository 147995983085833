import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

const ResultModal = ({ show, setResultShowModel, result }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setResultShowModel(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [show, setResultShowModel]);

  function getOrdinal(n) {
    if (!n) return "Y";
    const lastDigit = n % 10;
    const lastTwoDigits = n % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return n + "th";
    }
    switch (lastDigit) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
      default:
        return n + "th";
    }
  }

  return (
    <Modal
      show={show}
      onHide={() => setResultShowModel(false)}
      centered
      backdrop="static"
      dialogClassName="custom-modal"
    >
      <div className="modal-content">
        <div className="modal-header py-2 d-flex justify-content-between modelHeader text-white">
          <h3 className="modal-title flex-fill text-center fw-bolder">
            You {result.result}!
          </h3>
          <i
            onClick={() => setResultShowModel(false)}
            className="bi bi-x-circle fs-2"
            aria-label="Close"
            type="button"
          ></i>
        </div>
        <div className="modal-body p-0 pt-3 text-center">
          <div className="p-0 pb-2 d-flex align-items-center">
            <div className="col">
              <div className="d-flex justify-content-center">
                <div
                  className="custom-img-30 fw-bold border-0 p-2 rounded-circle d-flex justify-content-center align-items-center"
                  style={{ backgroundColor: "#e1dede" }}
                >
                  {result.teamA.code}
                </div>
              </div>
              <div className="fw-bold pt-1 px-1">{result.teamA.name}</div>
            </div>
            <div className="col text-center fs-12">
              <p className="m-0 text-danger">{result.league}</p>
              <i className="m-0 fw-bold text-secondary">V/S</i>
              <p className="m-0">
                {result.matchTime} {result.matchDate}
              </p>
            </div>
            <div className="col">
              <div className="d-flex justify-content-center">
                <div
                  className="custom-img-30 fw-bold border-0 p-2 rounded-circle d-flex justify-content-center align-items-center"
                  style={{ backgroundColor: "#e1dede" }}
                >
                  {result.teamB.code}
                </div>
              </div>
              <div className="fw-bold pt-1 px-1">{result.teamB.name}</div>
            </div>
          </div>
          <div className="mx-2">
            <h4
              className={`mb-1 mt-2 fw-bold ${
                result.result === "win" ? "text-success" : "text-danger"
              }`}
            >
              {result.result === "win" ? "Winning" : "Lost"} Amount
            </h4>
            <h1
              className={`mx-5 fw-bolder text-center py-2 rounded text-white ${
                result.result === "win" ? "greenShadowBg" : "bg-danger"
              }`}
            >
              &#x20B9;
              {result.result === "win"
                ? result.winningAmount
                : result.betAmount}
            </h1>
          </div>
          <div className="m-3 text-start">
            <h6 className="fw-bold mb-1">Bet on</h6>
            <div className="rounded lightGrayBg fs-12 py-2 px-2 mb-3">
              {result.question?.question
                .replace("{{runs}}", result.question?.runs || "X")
                .replace("{{overNumber}}", getOrdinal(result.question?.over))}
            </div>
            <div className="d-flex gap-5">
              <div className="w-50">
                <h6 className="fw-bold mb-1">Your Bet</h6>
                <div className="rounded lightGrayBg fs-12 py-2 px-2">
                  {result.choice === "teamA"
                    ? result.teamA?.name
                    : result.choice === "teamB"
                    ? result.teamB?.name
                    : result.choice === "yes"
                    ? "Yes"
                    : result.choice === "no"
                    ? "No"
                    : ""}
                </div>
              </div>
              <div className="w-50">
                <h6 className="fw-bold mb-1">Bet Amount</h6>
                <div className="rounded lightGrayBg fs-12 py-2 px-2">
                  &#x20B9;{result.betAmount}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <span className="fw-bold">Result: </span>
          <span>
            {result.result === "win"
              ? result.winningChoice === "teamA"
                ? result.teamA?.name
                : result.winningChoice === "teamB"
                ? result.teamB?.name
                : result.winningChoice === "yes"
                ? "YES"
                : "NO"
              : result.winningChoice === "teamA"
              ? result.teamB?.name
              : result.teamA?.name}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ResultModal;
