import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MatchQuestion from "./components/MatchQuestion";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestions,
  getUserPendingQuestion,
  setTotalBets,
} from "./redux/features/cricketSlice";
import LiveScore from "./components/LiveScore";
import { setLiveScores } from "./redux/features/webSocketSlice";
import { fetchUserBalance } from "../utils/userBalance";

const JoinMatch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { closeOrOpenBet, liveScores } = useSelector(
    (state) => state.webSocket
  );
  const { betQuestions, isLoading } = useSelector((state) => state.cricket);
  const [isBetOverByOver, setIsBetOverByOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const userBalance = JSON.parse(localStorage.getItem("userBalance"));

  const currentMatchId = location?.state?.match?.matchId;
  const match = liveScores[currentMatchId];

  const handleFetchBalance = async () => {
    setLoading(true);
    try {
      const balance = await fetchUserBalance(userId);
      return balance;
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedScores = JSON.parse(localStorage.getItem("liveScores"));
    const totalMatchQuestionsbets = JSON.parse(
      localStorage.getItem("totalQuestionsBets")
    );
    if (storedScores) {
      dispatch(setLiveScores(storedScores));
    }
    if (totalMatchQuestionsbets) {
      dispatch(setTotalBets(totalMatchQuestionsbets));
    }
    if (totalMatchQuestionsbets) {
      Object.entries(totalMatchQuestionsbets).forEach(
        ([matchId, questions]) => {
          Object.entries(questions).forEach(([questionId, totalBets]) => {
            dispatch(setTotalBets({ matchId, totalBets, questionId }));
          });
        }
      );
    }
  }, []);

  useEffect(() => {
    if (!currentMatchId) {
      navigate("/home");
      return;
    }
    if (currentMatchId) {
      dispatch(getQuestions(currentMatchId));
      dispatch(getUserPendingQuestion({ userId, currentMatchId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentMatchId === closeOrOpenBet.matchId && !closeOrOpenBet.status) {
      navigate("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeOrOpenBet]);

  useEffect(() => {
    const betOverQuestions = betQuestions.questions.filter(
      (que) => [8, 9, 10, 11].includes(que.questionId) && que.isCheckedByAdmin
    );
    setIsBetOverByOver(betOverQuestions.length > 0);
  }, [betQuestions.questions]);

  return (
    <main className="shadow">
      <header className="header position-sticky">
        <div className="">
          <div className="d-flex align-items-center px-3">
            <i
              onClick={() => navigate("/home")}
              className="bi bi-arrow-left-circle me-2 fs-3"
              type="button"
            ></i>
            <h6 className="m-0 fw-bolder flex-grow-1 text-center flex-fill py-3">
              Select a Contest to Join
            </h6>
            <div className="header-aside-join bg-white border rounded-pill text-black p-1 d-flex justify-content-between align-items-center">
              <img src="/assets/balRupees.svg" alt="rupee" />
              <div>
                <h6 className="m-0 fw-bold flex-fill px-1 pt-1">
                  {Number(userBalance.toFixed()).toLocaleString("en-IN")}
                </h6>
              </div>
              <i
                className={`fw-bolder bi bi-arrow-clockwise pe-1 fs-5 pt-1 text-success ${
                  loading ? "rotate" : ""
                }`}
                onClick={() => handleFetchBalance(userId)}
                type="button"
              ></i>
            </div>
          </div>
        </div>
        <LiveScore liveScore={match} />
      </header>
      <div className="mx-smm-2 mx-smm-3 py-3">
        <h5 className="fw-bold">Select a contest to join</h5>
        {betQuestions.isLoading ? (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="card mb-3">
                <div className="card__title loading"></div>
                <div className="card__description loading"></div>
              </div>
            ))}
          </>
        ) : betQuestions.questions.length > 0 ? (
          <>
            {[...betQuestions.questions]
              .sort((a, b) => a.questionId - b.questionId)
              .map(
                (que, index) =>
                  que.questionId < 8 &&
                  que.isCheckedByAdmin && (
                    <MatchQuestion
                      key={index}
                      matchQuestion={que}
                      betOverByOver={false}
                      teamA={location.state?.match.team_a}
                      teamB={location.state?.match.team_b}
                      match_id={currentMatchId}
                      league={location.state?.match.league}
                      inning={match?.inning || 1}
                      date={location.state?.formattedDate}
                      time={location.state?.formattedTime}
                      currentlyBattingTeamId={
                        match?.currentBattingTeam?.teamId || null
                      }
                    />
                  )
              )}
            {isBetOverByOver && (
              <div className="border-top border-start border-end rounded mb-3">
                <h6 className="text-center text-white fw-bolder rounded-top bg-primary py-2 ">
                  BET OVER BY OVER
                </h6>
                <div className="px-2">
                  {[...betQuestions.questions]
                    .sort((a, b) => a.questionId - b.questionId)
                    .map((que, index) => (
                      <div key={index}>
                        {que.questionId > 7 && que.isCheckedByAdmin && (
                          <MatchQuestion
                            key={index}
                            matchQuestion={que}
                            betOverByOver={true}
                            match_id={currentMatchId}
                            teamA={location.state?.match.team_a}
                            teamB={location.state?.match.team_b}
                            league={location.state?.match.league}
                            inning={match?.inning || 1}
                            date={location.state?.formattedDate}
                            time={location.state?.formattedTime}
                          />
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-40">
            <h5 className="text-danger text-center fw-bold">
              No Questions found for this match
            </h5>
          </div>
        )}
      </div>
    </main>
  );
};

export default JoinMatch;
