import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    liveScores: {},
    closeOrOpenBet: {}
};

const webSocketSlice = createSlice({
    name: "webSocket",
    initialState,
    reducers: {
        setLiveScores: (state, action) => {
            const newScores = action.payload;
            state.liveScores = {
                ...state.liveScores,
                ...newScores,
            };
            localStorage.setItem("liveScores", JSON.stringify(state.liveScores));
        },
        setCloseOrOpenBet: (state, action) => {
            state.closeOrOpenBet = action.payload;
        }
    },
});

export const {
    setLiveScores,
    setCloseOrOpenBet
} = webSocketSlice.actions;

export default webSocketSlice.reducer;
