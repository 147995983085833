import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoadingScreen = () => {
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const accessToken = params.get("token");
  const returnUrl = params.get("return_url");
  const userId = params.get("user");
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [error, setError] = useState("");

  const localUserId = JSON.parse(localStorage.getItem("userId"));
  const token = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    const verifyToken = async () => {
      if (accessToken && userId) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/user/verifyToken`,
            { token: accessToken, gameType: "cricket" }
          );

          if (response.data.statusCode === 200) {
            localStorage.setItem("userId", JSON.stringify(response.data.data.userId));
            localStorage.setItem("accessToken", JSON.stringify(response.data.token));
          }
        } catch (error) {
          localStorage.removeItem("userId");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("userBalance");
        }
      }

      if (returnUrl) {
        localStorage.setItem("return_url", JSON.stringify(returnUrl));
      }
    };

    verifyToken(); 

  }, [accessToken, returnUrl, userId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadingScreen(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if ( !showLoadingScreen) {
      if (localUserId && token && accessToken && userId) {
        navigate("/home");
      } else {
        setError("URL parameters are missing or invalid. Please check token and user.");
      }
    }
  }, [showLoadingScreen, token, localUserId, navigate]);

  return (
    <div>
      {showLoadingScreen && (
        <div
          className="d-flex justify-content-center align-items-center position-relative w-100"
          style={{ height: "100vh" }}
        >
          <img
            src="/assets/compressLoading.gif"
            alt="Loading"
            className="w-100 h-100"
          />
        </div>
      )}
      {error &&
        <main className='d-flex justify-content-center align-items-center h-80 shadow'>
          <h4 className='text-danger fw-bold text-center px-1'>
            URL parameters are missing or invalid. Please check token and user
          </h4>
        </main>
      }
    </div>
  );
}
export default LoadingScreen;