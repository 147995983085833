import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MyMatch from "./components/MyMatch";
import { getUserBets } from "./redux/features/cricketSlice";

const MyMatches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortByDateModel, setSortByDateModel] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [applied, setApplied] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));

  const { isLoading, allBets, winBets, lossBets, pendingBets, dateBets } =
    useSelector((state) => state.cricket);

  useEffect(() => {
    if (
      userId &&
      (!allBets.hasFetch ||
        (status === "win" && !winBets.hasFetch) ||
        (status === "loss" && !lossBets.hasFetch) ||
        (status === "pending" && !pendingBets.hasFetch) ||
        applied)
    ) {
      dispatch(
        getUserBets({
          userId: userId,
          status: status,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, applied, userId, startDate, allBets, endDate]);

  let bets = [];
  if (status === "win") {
    bets = winBets.winBets;
  } else if (status === "loss") {
    bets = lossBets.lossBets;
  } else if (status === "pending") {
    bets = pendingBets.pendingBets;
  } else if (status === "date" && applied) {
    bets = dateBets;
  } else {
    bets = allBets.allBets;
  }

  return (
    <main className="shadow">
      <header className="header position-sticky">
        <div className="d-flex align-items-center px-4">
          <i
            onClick={() => navigate("/home")}
            className="bi bi-arrow-left-circle me-2 fs-3"
            type="button"
          ></i>
          <h6 className="m-0 fw-bold flex-grow-1 text-center py-3">
            My Matches
          </h6>
        </div>
        <div className="text-black bodyBgColor">
          <div className="d-flex px-2 py-3 gap-1">
            <button
              className={`flex-fill border d-flex align-items-center rounded-pill lightGrayBg fs-12 py-2
                  ${status === "" ? "bg-secondary fs-12 text-white" : ""}`}
              onClick={() => {
                setStatus("");
                setApplied(false);
              }}
            >
              <img src="/assets/redBall.svg" alt="ball" />
              <div className="flex-fill">All</div>
            </button>
            <button
              className={`flex-fill border d-flex align-items-center rounded-pill lightGrayBg fs-12 py-2
                  ${status === "win" ? "bg-secondary fs-12 text-white" : ""}`}
              onClick={() => {
                setStatus("win");
                setApplied(false);
              }}
            >
              <img src="/assets/redBall.svg" alt="ball" />
              <div className="flex-fill">Win</div>
            </button>
            <button
              className={`flex-fill border d-flex align-items-center rounded-pill lightGrayBg fs-12 py-2
                  ${status === "loss" ? "bg-secondary fs-12 text-white" : ""}`}
              onClick={() => {
                setStatus("loss");
                setApplied(false);
              }}
            >
              <img src="/assets/redBall.svg" alt="ball" />
              <div className="flex-fill">Lost</div>
            </button>
            <button
              className={`flex-fill border d-flex align-items-center rounded-pill lightGrayBg fs-12 py-2
                  ${
                    status === "pending" ? "bg-secondary fs-12 text-white" : ""
                  }`}
              onClick={() => {
                setStatus("pending");
                setApplied(false);
              }}
            >
              <img src="/assets/redBall.svg" alt="ball" />
              <div className="flex-fill">Pending</div>
            </button>
            <button
              className={`flex-fill border d-flex align-items-center rounded-pill lightGrayBg fs-12 py-2
                  ${
                    status === "date" && applied
                      ? "bg-secondary text-white"
                      : ""
                  }`}
              onClick={() => {
                setStatus("date");
                setSortByDateModel(true);
                setApplied(false);
                setStartDate("");
                setEndDate("");
              }}
            >
              <img src="/assets/sortA.svg" alt="sort" />
              <span className="flex-fill">By Date</span>
              <img src="/assets/sortB.svg" alt="sort" />
            </button>
            {sortByDateModel && (
              <>
                <div
                  className="modal-backdrop fade show"
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1040,
                  }}
                ></div>
                <div
                  className="modal show"
                  style={{
                    display: "flex",
                    position: "fixed",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1050,
                    left: 0,
                    top: 0,
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div className="w-100-modelBox w-22 modal-dialog modal-dialog-centered mx-3 w-380">
                    <div className="modal-content">
                      <div className="modal-header py-2 d-flex justify-content-between modelHeader text-white">
                        <h5 className="modal-title flex-fill text-center fw-bolder">
                          Sort By Date
                        </h5>
                        <i
                          onClick={() => setSortByDateModel(false)}
                          className="bi bi-x-circle fs-4"
                          type="button"
                        ></i>
                      </div>
                      <div className="modal-body p-0 py-3 text-center">
                        <h4 className="text-danger fw-bold ">Start Date</h4>
                        <p className="fs-12 text-secondary">
                          Select your Start date where you want to see match
                          history
                        </p>
                        <input
                          type="date"
                          className="border py-2 px-3 rounded w-75"
                          placeholder="Select Start Date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="modal-body p-0 py-3 text-center">
                        <h4 className="text-danger fw-bold ">End Date</h4>
                        <p className="fs-12 text-secondary">
                          Select your End date where you want to see match
                          history
                        </p>
                        <input
                          type="date"
                          className="border py-2 px-3 rounded w-75"
                          placeholder="Select End Date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="modal-footer justify-content-center my-2">
                        <button
                          className={`text-white fs-3 fw-bold redShadowBg border rounded-pill py-1 px-5 ${
                            startDate && endDate ? "" : "disabledDiv"
                          }`}
                          onClick={() => {
                            setSortByDateModel(false);
                            setApplied(true);
                          }}
                          disabled={!startDate || !endDate}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </header>
      <div className="mx-smm-2 mx-smm-3">
        {isLoading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <div className="card mb-3" key={index}>
              <div className="card__title loading"></div>
              <div className="card__description loading"></div>
            </div>
          ))
        ) : bets?.length < 1 ? (
          <div className="d-flex justify-content-center align-items-center h-80">
            <h5 className="text-danger text-center fw-bold">
              No Bets Found {status ? `for ${status} bet` : ""}
            </h5>
          </div>
        ) : (
          bets.map((match) => <MyMatch match={match} key={match.betId} />)
        )}
      </div>
    </main>
  );
};

export default MyMatches;
