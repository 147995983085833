import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_URL}`,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem("accessToken"));
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
