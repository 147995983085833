import React from "react";

const MyMatch = ({ match }) => {
  const {
    teamA,
    teamB,
    league,
    question,
    choice,
    betAmount,
    result,
    winningAmount,
    matchDate,
    matchTime,
    betTime,
  } = match;

  const outcomes = {
    win: {
      teamA: teamA?.name,
      teamB: teamB?.name,
      yes: "YES",
      no: "NO",
    },
    loss: {
      teamA: teamB?.name,
      teamB: teamA?.name,
      yes: "NO",
      no: "YES",
    },
  };

  const resultText = outcomes[result]?.[choice] || "Pending";

  function getOrdinal(n) {
    if (!n) return "Y";
    const lastDigit = n % 10;
    const lastTwoDigits = n % 100;
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return n + "th";
    }
    switch (lastDigit) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
      default:
        return n + "th";
    }
  }
  return (
    <div className="p-0 rounded mb-3 shadow-lg bg-white">
      <div className="p-0 py-1 border-bottom d-flex align-items-center letter-spacing-negative">
        <div className="me-1 w-50">
          <div className="d-flex justify-content-center">
            <div
              className="custom-img-30 fw-bold border-0 rounded-circle d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#e1dede" }}
            >
              {teamA?.code}
            </div>
          </div>

          <div className="text-center">{teamA?.name}</div>
        </div>

        <div className="text-center fs-10">
          <p className="m-0 text-gradient text-smm-truncate">{league}</p>
          <i className="m-0 fw-bold" style={{ color: "#A7A7A7" }}>
            V/S
          </i>
          <p className="m-0 text-truncate">
            {matchTime} {matchDate}
            <br />{" "}
            {new Date(betTime).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </p>
        </div>

        <div className="me-1 w-50">
          <div className="d-flex justify-content-center">
            <div
              className="custom-img-30 fw-bold border-0 rounded-circle d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#e1dede" }}
            >
              {teamB?.code}
            </div>
          </div>
          <div className="text-center">{teamB?.name}</div>
        </div>
      </div>
      <div className="d-flex px-1 flex-fill">
        <div className="flex-fill m-1">
          <div className="mb-1">Bet on</div>
          <div className="rounded lightGrayBg fs-10 py-1 px-1">
            {question.question
              .replace("{{runs}}", question.runs || "X")
              .replace("{{overNumber}}", getOrdinal(question.over))}
          </div>
        </div>
        <div className="flex-fill m-1">
          <div className="mb-1">Your Bet</div>
          <div className="rounded lightGrayBg fs-10 py-1 px-1">
            {choice === "teamA"
              ? teamA?.name
              : choice === "teamB"
                ? teamB?.name
                : choice === "yes"
                  ? "YES"
                  : choice === "no"
                    ? "NO"
                    : ""}
          </div>
        </div>
        <div className="flex-fill m-1">
          <div className="mb-1">Bet Amount</div>
          <div className="rounded lightGrayBg fs-10 py-1 px-2">
            ₹{betAmount}
          </div>
        </div>
      </div>
      <div
        className={`rounded-bottom ${result === "win"
            ? "greenShadowBg"
            : result === "loss"
              ? "bg-danger"
              : "bg-secondary"
          } p-1 d-flex align-items-center`}
      >
        <div className="w-50 d-flex p-0.5 rounded">
          <div className="w-smm-85 w-smm-100 bg-white py-1 ps-1 rounded flex-fill d-flex letter-spacing-negative">
            <img src="/assets/result.svg" alt="result" className="pe-1" />
            <span className="fw-bolder">Result:</span>
            <span className="ps-1 truncate" style={{ color: "#606060" }}>
              {resultText}
            </span>
          </div>
        </div>
        {result !== "pending" && (
          <div className="w-50 d-flex align-items-center gap-1 flex-fill">
            <span className="fw-bolder text-white flex-fill text-end">
              {result === "win" ? (
                <img
                  src="/assets/TrophyIcon.svg"
                  alt="result"
                  className="pe-1"
                />
              ) : (
                ""
              )}
              {result === "win"
                ? "You Won"
                : result === "loss"
                  ? "You Lost"
                  : ""}
            </span>
            <span className="w-50 bg-white rounded p-1 text-center fw-bold">
              ₹
              {(result === "win" ? winningAmount : betAmount)
                .toFixed()
                .toLocaleString("en-IN")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyMatch;
