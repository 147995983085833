import { Routes, Route } from 'react-router-dom';
import { WebSocketProvider, useWebSocket } from './webSocketContext';
import Home from './pages/Home';
import JoinMatch from './pages/JoinMatch';
import MyMatches from './pages/MyMatches';
import ResultModal from './pages/components/ResultModel';
import BetConfirmedModel from './pages/components/BetConfirmModel';
import BetErrorModel from './pages/components/BetErrorModel';

const ProtectedRoutesContent = () => {
  const { showResultModel, setResultShowModel, result, setBetConfirmedModel, betConfirmedModel, betErrorModel, setBetErrorModel, errorMessage } = useWebSocket();

  return (
    <>
      {showResultModel && (
        <ResultModal
          show={showResultModel}
          setResultShowModel={setResultShowModel}
          result={result}
        />
      )}
      {betConfirmedModel && (
        <BetConfirmedModel
          show={true}
          setBetConfirmedModel={setBetConfirmedModel}
        />
      )}
      {betErrorModel && (
        <BetErrorModel
          show={true}
          setBetErrorModel={setBetErrorModel}
          errormsg={errorMessage}
        />
      )}
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="join-match" element={<JoinMatch />} />
        <Route path="my-matches" element={<MyMatches />} />
      </Routes>
    </>
  );
};

const ProtectedRoutes = () => (
  <WebSocketProvider>
    <ProtectedRoutesContent />
  </WebSocketProvider>
);

export default ProtectedRoutes;